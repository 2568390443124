.sidebar {
    height: 100vh;
    width: 200px;
    color: rgb(255 255 255 / 70%);
    background-color: #051e34;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    float: left;
}
  
.rootContainer {
    width: 100%;
    height: 100%;
}
  
.main {
    height: 100%;
    width: 100%;
    background-color: #f6f7f9;
    flex-grow: 1;
}